import {useSearchParams} from "react-router-dom";

const searchParams = {
    amount: 'amount',
    paymentId: 'paymentId',
    purchaseType: 'purchaseType',
    purchaseId: 'purchaseId'
}

export const Content = () => {
    const [params] = useSearchParams()
    return <main className="content">
        <div className="container">
            <div className="content_inner">
                <p className="content__text">
                    Payment Successfully Authorized in your card. Now, we are starting your Topup request process.
                    You will transfer automatically to WhatsApp,
                    or you can close this page and continue the process through your WhatsApp app conversation.
                </p>
                <div className="content__info">
                    <strong className="content__info-title">Total Payment Amount</strong>
                    <div className="content__info-amount">
                        <span>$</span>
                        <div>{params.get(searchParams.amount)}</div>
                    </div>

                    <strong className="content__info-title">Payment Message</strong>
                    <div className="content__info-message">
                        <p>
                            Payment Processed
                        </p>
                        <span>{params.get(searchParams.paymentId)}</span>
                    </div>

                    <strong className="content__info-title">Payment Method</strong>
                    <div className="content__info-method">
                        <p>
                            {params.get(searchParams.purchaseType)}
                        </p>
                        <span>{params.get(searchParams.purchaseId)}</span>
                    </div>
                </div>
            </div>
        </div>
    </main>
}
export const CheckMarkIcon = () => {
    return <svg
        height="800px"
        width="800px"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        xmlSpace="preserve"
    >
        <g>
            <g id="check">
                <g>
                    <polygon
                        style={{
                            fill: "#009100",
                        }}
                        points="11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 26.305,3.123 32,8.818  "
                    />
                </g>
            </g>
        </g>
    </svg>
}
import {CheckMarkIcon} from "../assets/CheckMarkIcon";

export const Header = () => {
    return <header className="header">
        <div className="container">
            <div className="header_inner">
                <div className="header__icon">
                    <CheckMarkIcon/>
                    <h1 className="header__title">Thank you for your payment!</h1>
                </div>
            </div>
        </div>
    </header>
}
export const Footer = () => {
    return <footer className="footer">
        <div className="container">
            <p>
                Thank you for trusting and using AminPay Services.
                If you need any support, don’t hesitate to get in touch with our customer
                support department through WhatsApp at <a href="https://wa.me/93705280280" rel="noreferrer" target="_blank">+93705280280</a>
            </p>
        </div>
    </footer>
}
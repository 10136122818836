import './index.css'
import {Footer} from "./components/Footer";
import {Header} from "./components/Header";
import {Content} from "./components/Content";

function App() {
  return (
    <div className='app'>
        <Header />
        <Content/>
        <Footer />
    </div>
  );
}

export default App;
